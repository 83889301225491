import React, { useState } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowIcon from "assets/icons/chevron-down-light.svg";
import Carrusel from "react-material-ui-carousel";
import ArrowDarkIcon from "assets/icons/chevron-down-dark.svg";
import Icon from "components/Icon";
import Button from "components/Button";
import SinglePlayer from "components/SinglePrayer";
import Select from "components/Select";
import Accordion from "components/Accordion";

export function accordionMonthlyFunctions() {
  function onChangeSearch(details, event) {
    const search = details?.conferences.filter((element) =>
      element.value.toLowerCase().includes(event.target.value.toLowerCase())
    );
    return search;
  }

  return {
    onChangeSearch,
  };
}

export function AccordionMonthlyStyled(props) {
  const {
    description,
    title,
    monthly,
    year,
    onChange,
    isExpanded,
    css,
    background,
    handleFavorite,
    handleDislike,
    handlevideo,
    onChangeSelectLanguage,
    details,
    isSmall,
    isVideo,
    index,
  } = props;
  const { t } = useTranslation();
  const [searchConferences, setSearchConferences] = useState(null);
  const classes = useStyles({
    mediaQuerySm: isSmall,
  });

  const sumaryMonthly = () => {
    return (
      <div
        id={`acc__sumamary_component_content_${index}`}
        data-testid={`acc__sumamary_component_content`}
      >
        <p
          data-testid={`txt_acc__sumamary_component_monthly_year`}
          className={classes.contentDateIntention}
        >{`${monthly} ${year}`}</p>

        <h1
          className={classes.titleMonthly}
          data-testid={`txt_acc__sumamary_component_title`}
        >
          {title}
        </h1>

        {isExpanded ? (
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            classes={{ root: classes.formControlLabel }}
            control={
              <div>
                {description.length ? (
                  <div className={classes.description}>
                    <div
                      id={`ico_description_${index}`}
                      data-testid={`ico_description_${index}`}
                      className={classes.contentDescription}
                    >
                      {/*salto de linea*/}
                      <p data-testid={`txt_description_${index}`}>
                        {description}
                      </p>
                      <hr />
                    </div>
                  </div>
                ) : null}
                {isVideo && (
                  <Button
                    css={classes.buttonVideo}
                    type="primary"
                    text={t(
                      `components.accordionMonthly.Watch {{month}} Video`,
                      {
                        month: monthly.toLowerCase(),
                      }
                    )}
                    onClick={() => handlevideo()}
                    id={`btn_summary_video`}
                  />
                )}
              </div>
            }
          />
        ) : null}
      </div>
    );
  };
  
  const detailsMonthly = () => {
    return (
      <section>
        <div className={classes.contentPrayer}>
          {details?.prayers.map((prayer, idx) => (
            <SinglePlayer
              index={idx}
              key={idx}
              title={t(
                `components.accordionMonthly.${prayer?.type?.description} Prayer`
              )}
              isTotalDescription={true}
              isModal={false}
              description={prayer?.description}
              css={classes.cardPrayer}
              handleFavorite={() => {
                handleFavorite(prayer?.id, prayer.type.code, idx, index);
              }}
              handleDislike={() => {
                handleDislike(prayer?.id, prayer.type.code, idx, index);
              }}
              owner={prayer.id}
              ownerType={prayer.type.code}
              isMonthly={true}
              indexAccordion={index || 0}
            />
          ))}
        </div>
        { false && !!details?.conferences?.length && ( //Se colocal false al comienzo para deshabilitar por pedido de tarjeta CTPD-61
          <div
            id={`txt_bishop_${index}`}
            data-testid={`txt_bishop_${index}`}
            className={classes.contentConferenceEpiscopal}
          >
            <h1 data-testid={`txt_bishop_title_${index}`}>
              {t(
                `components.accordionMonthly.Prayer Intention of the Conference of Bishops`
              )}
            </h1>
            <div>
              <Select
                id={`txt_bishop_select_${index}`}
                isSearch={true}
                selected={details?.languageConference}
                options={
                  searchConferences ? searchConferences : details?.conferences
                }
                icon={<Icon icon={ArrowDarkIcon} alt="arrow" />}
                onChangeSelect={onChangeSelectLanguage}
                css={classes.selectLanguage}
                onChangeSearch={(event) =>
                  setSearchConferences(
                    accordionMonthlyFunctions().onChangeSearch(details, event)
                  )
                }
              />
            </div>
            <p data-testid={`txt_bishop_description_${index}`}>
              {details?.languageConference.description}
            </p>
          </div>
        )}
        {!!details?.attitudes?.length && (
          <div
            id={`txt_attitudes_${index}`}
            data-testid={`txt_attitudes_${index}`}
            className={classes.contentAttitude}
          >
            <h1 data-testid={`txt_attitudes_title_${index}`}>
              {t(`components.accordionMonthly.Attitudes`)}
            </h1>
            <Carrusel
              navButtonsProps={{
                style: {
                  backgroundColor: "transparent",
                  borderRadius: 0,
                },
              }}
              cycleNavigation={false}
              autoPlay={false}
              navButtonsAlwaysInvisible={true}
              animation="slide"
            >
              {details?.attitudes.map((attitude, index) => {
                if (isSmall) {
                  return (
                    <div
                      id={`txt_attitudes_description_${index}`}
                      className={classes.contentCardAttitude}
                      key={index}
                    >
                      <p data-testid={`txt_attitudes_description_${index}`}>
                        {attitude?.description}
                      </p>
                    </div>
                  );
                } else {
                  if (Array.isArray(attitude))
                    return attitude.map(({ description }, index) => (
                      <div
                        id={`txt_attitudes_description_${index}`}
                        className={classes.contentCardAttitude}
                        key={index}
                      >
                        <p data-testid={`txt_attitudes_description_${index}`}>
                          {description}
                        </p>
                      </div>
                    ));
                }
              })}
            </Carrusel>
          </div>
        )}
      </section>
    );
  };
  return (
    <Accordion
      css={css}
      sumary={sumaryMonthly()}
      details={detailsMonthly()}
      onChange={onChange}
      background={background}
      isExpanded={isExpanded}
      isSm={isSmall}
      iconArrow={ArrowIcon}
    />
  );
}
