import makeStyles from "@material-ui/core/styles/makeStyles";
export const useStyles = makeStyles({
  contentDateIntention: (props) => {
    let style = {
      fontFamily: "Nunito Sans",
      fontSize: "14px",
      textTransform: "uppercase",
      color: "#FFFFFF",
      margin: "40px 0px 8px 0px",
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        margin: "24px 0px 8px 0px",
      };
    }

    return style;
  },

  description: (props) => {
    let style = {
      display: "flex",
      marginBottom: "32px",
      alignItems: "flex-start",
      textTransform: "none",
    };
    if (props.mediaQuerySm) {
      style.marginBottom = 0;
    }
    return style;
  },
  avatar: {
    width: "72px",
    height: "72px",
  },

  contentDescription: (props) => {
    let style = {
      maxWidth: "588px",
      wordBreak: "break-word",
      whiteSpace: "pre-line",
      "& p": {
        fontFamily: "Nunito Sans",
        color: "#FFFFFF",
      },
      "&>p:first-child": {
        fontWeight: 600,
        fontSize: "18px",
        textTransform: "none !important",
        margin: "0px 0px 8px 0px",
      },
      "&>p:last-child": {
        fontSize: "14px",
        margin: 0,
      },
      "& hr": {
        visibility: "hidden",
      },
    };
    if (props.mediaQuerySm) {
      style.marginLeft = 0;
      style["&>p:first-child"] = {
        ...style["&>p:first-child"],
        fontSize: "16px",
        fontWeight: "normal",
        margin: "0px 0px 24px 0px",
      };
      style["& hr"] = {
        visibility: "visible",
        margin: "24px 0px",
        opacity: 0.2,
        border: "1px solid #FFFFFF",
      };
    }
    return style;
  },
  buttonVideo: {
    marginBottom: "64px",
  },
  contentPrayer: (props) => {
    let style = {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "100%",
      flexDirection: "row",
    };
    if (props.mediaQuerySm) {
      style.flexDirection = "column";
    }
    return style;
  },
  cardPrayer: (props) => {
    let style = {
      margin: "0px 44px 46px 0px",
      width: "50%",
      "& >div:first-child": {
        width: "100%",
        padding: 0,
        "& >div:first-child": {
          padding: 0,
          "& >div:first-child": {
            padding: 0,
          },
        },
      },
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        margin: "24px 0px 0px 0px",
        width: "100%",
      };
    }
    return style;
  },
  contentConferenceEpiscopal: (props) => {
    let style = {
      "&>div": {
        width: "10%",
      },
      "&>h1": {
        fontFamily: "Nunito Sans",
        fontWeight: 600,
        fontSize: "22px",
        color: "#444C67",
      },
      "&>p": {
        fontFamily: "Nunito Sans",
        fontSize: "16px",
        color: "#444C67",
        margin: "24px 0px 64px",
        whiteSpace: "pre-wrap",
      },
    };
    if (props.mediaQuerySm) {
      style["&>h1"].margin = "32px 0px 8px";
      style["&>p"].margin = "8px 0px 32px";
    }
    return style;
  },
  selectLanguage: {
    "&>button": {
      width: "auto !important",
      padding: 0,
      "& img": {
        width: "9px",
        height: "9px",
        marginLeft: "13px",
      },
    },
  },
  contentAttitude: (props) => {
    let style = {
      margin: "0px",
      "& .CarouselItem": {
        "& div:first-child": {
          display: "flex !important",
          flexDirection: "row !important",
          alignItems: "start",
          justifyContent: "center",
        },
      },
      "& h1": {
        fontFamily: "Nunito Sans",
        fontWeight: 600,
        fontSize: "22px",
        color: "#444C67",
        margin: "0px 0px 24px",
      },
    };
    if (props.mediaQuerySm) {
      style.margin = "0px 0px 24px";
      style["& h1"].margin = "0px 0px 8px";
    }
    return style;
  },
  contentCardAttitude: (props) => {
    let style = {
      background: "#F6EFF5",
      width: "328px",
      borderRadius: "12px",
      padding: "32px",
      marginRight: "16px",
      whiteSpace: "pre-line",

      "&:last-child": {
        marginRight: "0px",
      },
      "& p": {
        fontFamily: "Nunito Sans",
        fontSize: "16px",
        color: "#444C67",
        margin: 0,
      },
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        marginRight: "0px ",
        padding: "24px",
      };
    }
    return style;
  },
  formControlLabel: {
    margin: 0,
  },
  titleMonthly: (props) => {
    let style = {
      fontFamily: "Libre Baskerville",
      fontSize: "32px",
      color: "#FFFFFF",
      margin: "0px 0px 40px 0px",
      whiteSpace: "pre-line",
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        margin: "0px 0px 24px 0px",
        fontSize: "22px",
      };
    }
    return style;
  },
});
